import React from 'react';

import Section from './Section';
import SectionHeader from './SectionHeader';
import Stat from './Stat';
import './StatsSection.scss';

function StatsSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container" data-aos="fade" data-aos-delay="600">
        <SectionHeader
          title={props.title}
          size={1}
          spaced={true}
          className="has-text-centered"
        />
        <nav className="StatsSection__level level">
          {props.items.map((item, index) => (
            <Stat key={index} title={item.title} stat={item.stat} />
          ))}
        </nav>
      </div>
    </Section>
  );
}

export default StatsSection;
