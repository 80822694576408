import React from 'react';
import Section from './Section';
import SectionHeader from './SectionHeader';
import TeamBios from './TeamBios';

function TeamBiosSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
          data-aos="fade"
        />
        <TeamBios people={props.people} />
      </div>
    </Section>
  );
}

export default TeamBiosSection;
