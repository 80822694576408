import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import HeroSectionSimpleWhite from '../components/HeroSectionSimpleWhite';
import StatsSection from '../components/StatsSection';
import TeamBiosSection from '../components/TeamBiosSection';
import ClientsSection from '../components/ClientsSection';
import SEO from '../components/SEO';

export const avatar128Image = graphql`
  fragment avatar128Image on File {
    childImageSharp {
      fixed(fit: COVER, height: 128, width: 128) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;

/**
 * Save the avatars in the folder ./src/images/team and update the paths
 * (relative to ./src/images/) in this section. Then update the
 * name/role/bio/etc. down below.
 */
export const query = graphql`
  query {
    avatar1: file(relativePath: { eq: "team/david.jpg" }) {
      ...avatar128Image
    }
    avatar2: file(relativePath: { eq: "team/brian.jpg" }) {
      ...avatar128Image
    }
    avatar3: file(relativePath: { eq: "team/mark.jpeg" }) {
      ...avatar128Image
    }
    avatar4: file(relativePath: { eq: "team/jace.jpeg" }) {
      ...avatar128Image
    }
    avatar5: file(relativePath: { eq: "team/blank.jpeg" }) {
      ...avatar128Image
    }
    avatar6: file(relativePath: { eq: "team/justin.jpg" }) {
      ...avatar128Image
    }
    avatar7: file(relativePath: { eq: "team/shane.jpg" }) {
      ...avatar128Image
    }
        avatar8: file(relativePath: { eq: "team/chad.jpg" }) {
      ...avatar128Image
    }
    avatar9: file(relativePath: { eq: "team/cole.jpg" }) {
      ...avatar128Image
    }
    prismic {
      clients: allClients {
        edges {
          node {
            name
            logo
            logoSharp {
              childImageSharp {
                fixed(fit: INSIDE, height: 90, width: 150) {
                  ...GatsbyImageSharpFixed_withWebp
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

function AboutPage(props) {
  return (
    <Layout>
      <SEO title="About" />
      <PageHeadSection title="About" />
      <HeroSectionSimpleWhite
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Forward-thinking real estate strategies and solutions."
        subtitle="The team at Horizon has a proven experience in commercial real estate sales and leasing in North Dakota and surrounding areas. With engagement in all segments of the commercial real estate market, we offer expertise from retail, industrial, and office sales and leasing to strategic land development. At Horizon, we love what we do, take pride in our work, and always pursue the absolute best for our clients. We’re confident you’ll agree."
        data-aos="fade-up"
      />
{/*      <StatsSection
        color="primary"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Our partner Dakota REIT"
        items={[
          {
            title: 'Real Estate Assets',
            stat: '535607542',
          },
          {
            title: 'Square Feet of Commercial',
            stat: '2100000',
          },
          {
            title: 'Multifamily Units',
            stat: '3010',
          },
          {
            title: 'Investors',
            stat: '1050',
          },
        ]}
      />*/}

      <TeamBiosSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Meet the Team"
        subtitle=""
        people={[
          {
            avatar: (
              <Img
                fixed={props.data.avatar1.childImageSharp.fixed}
                alt="David Schlossman, CCIM"
                className="is-rounded"
              />
            ),
            name: 'David Schlossman, CCIM',
            role: 'President & Managing Broker',
            bio:
            'David grew up in Fargo and is a 4th generation real estate entrepreneur. Prior to joining Horizon, David was a senior agent with Goldmark Commercial for nearly a decade where he built strong relationships earned through hard work and trust. David graduated from Southern Methodist University in Dallas, TX from the Cox School of Business with a degree in finance and is an active CCIM designee (Certified Commerical Investor Member)',
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://www.facebook.com/david.schlossman',
            linkedinUrl: 'https://linkedin.com/in/fargo',
            ndlicense: '8360',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar6.childImageSharp.fixed}
                alt="Justin Berg"
                className="is-rounded"
              />
            ),
            name: 'Justin Berg',
            role: 'Vice President',
            bio:
            "Justin Berg is an accomplished real estate developer and broker with over two decades of experience, specializing in building community-focused, sustainable developments in the Red River Valley. With an accounting background and an MBA, he leverages data-driven decisions for equitable solutions in land development. Founder of Grassroots Development, Justin's notable projects include New Haven of Reile's Acres, Prairie Parkway, and award-winning historic rehabilitations like the Front Street Taproom in Fargo, which garnered recognition from the ND Historic Society. His work emphasizes the creation of vibrant, people-centered spaces while preserving historical integrity.",
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://www.facebook.com/justin.berg.923?mibextid=ZbWKwL',
            linkedinUrl: 'https://www.linkedin.com/in/justin-berg-885567a/',  
            ndlicense: '7680',
          },
          {
          avatar: (
              <Img
                fixed={props.data.avatar7.childImageSharp.fixed}
                alt="Shane Cullen"
                className="is-rounded"
              />
            ),
            name: 'Shane Cullen',
            role: 'Broker Associate Partner',
            bio:
            "Shane Cullen is an experienced real estate broker and a proven leader with a 12-year track record, including a term as president of the Fargo Moorhead Area Association of Realtors. His commitment extends to board service for various organizations. Shane's expertise ranges from residential sales to lot sales in prestigious developments like River’s Bend, under the mentorship of the esteemed Steve Stoner. He continues to handle lot sales for Grassroots Development's key projects, including New Haven of Reile’s Acres and Prairie Parkway. Shane is also a principal in Balzum Cullen Construction, LLC, where his construction acumen and dedication to quality are integral to the success of shop condominiums and commercial developments. His hands-on approach ensures excellence in every project.",
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://m.facebook.com/shanecullenrealtor/',
            linkedinUrl: 'https://www.linkedin.com/in/shane-cullen-94a04772/',
            ndlicense: '8752',
          },
            {
            avatar: (
              <Img
                fixed={props.data.avatar4.childImageSharp.fixed}
                alt="Jace Engelstad"
                className="is-rounded"
              />
            ),
            name: 'Jace Engelstad',
            role: 'Senior Commercial Agent',
            bio:
            'Jace graduated from The Offutt School of Business from Concordia College with Deans list honors and is a CCIM Candidate (Certified Commercial Investment Manager). Jace has had a passion for selling and working with clients since a very young age, prior to joining Horizon as a full time Commercial Agent, he started a lakes area lawncare company that included many services rendered to his clients that grew to over 30 different properties. Jace has grown up in the area and has a keen knowledge on the Fargo real estate market, driven by his number one goal of winning for his clients, and having the same energy and drive in helping people either begin or grow their business dream (vision). Jace is a cultivated and extremely motivated individual that is striving to grow and create new business on every level to this ever-expanding community.',
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://www.facebook.com/david.schlossman',
            linkedinUrl: 'https://linkedin.com/in/fargo',
            ndlicense: '10937',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar2.childImageSharp.fixed}
                alt="Brian Tulibaski"
                className="is-rounded"
              />
            ),
            name: 'Brian Tulibaski, MBA',
            role: 'Commercial Agent',
            bio:
              'Brian Tulibaski, MBA, is a seasoned professional joining Horizon Real Estate Group as a Commercial Real Estate Agent and Real Estate Investor. With a strong background in sales and operations leadership, Brian brings over 25 years of experience in exceeding sales and profit expectations. As a father of three boys, Brian understands the importance of building a secure future through strategic investments and financial independence. His expertise and passion lie in the realms of economics, passive investing, commercial real estate, financial independence, and real estate development. Brian holds an MBA from North Dakota State University, where he honed his business acumen and leadership skills. Throughout his career, he has demonstrated exceptional abilities in P&L management, human resource management, business development, and strategic planning.',
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://facebook.com',
            linkedinUrl: 'https://linkedin.com',
            ndlicense: '9607',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar3.childImageSharp.fixed}
                alt="Mark Richman"
                className="is-rounded"
              />
            ),
            name: 'Mark Richman',
            role: 'Associate Broker',
            bio:
              'Mark graduated from North Dakota State University in Business Economics and began work in commercial real estate in 1976. From 1988 through 2010 he worked with Coldwell Banker Commercial Real Estate in Fargo. Mark is a CCIM (Certified Commercial Investment Member) and a CPM (Certified Property Manager).',            
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://facebook.com',
            linkedinUrl: 'https://linkedin.com',
            ndlicense: '2364',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar8.childImageSharp.fixed}
                alt="Chad Bartholomay"
                className="is-rounded"
              />
            ),
            name: 'Chad Bartholomay',
            role: 'Real Estate Salesperson',
            bio: "",
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://facebook.com',
            linkedinUrl: 'https://linkedin.com',
            ndlicense: '8931',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar9.childImageSharp.fixed}
                alt="Cole Foss"
                className="is-rounded"
              />
            ),
            name: 'Cole Foss',
            role: 'Real Estate Salesperson',
            bio: "",
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://facebook.com',
            linkedinUrl: 'https://linkedin.com',
            ndlicense: '11133',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar5.childImageSharp.fixed}
                alt="Tim Gleason"
                className="is-rounded"
              />
            ),
            name: 'Tim Gleason',
            role: 'Real Estate Salesperson',
            bio:
              "",
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://facebook.com',
            linkedinUrl: 'https://linkedin.com',
            ndlicense: '10567',
          },
          {
            avatar: (
              <Img
                fixed={props.data.avatar5.childImageSharp.fixed}
                alt="Nick Hamilton"
                className="is-rounded"
              />
            ),
            name: 'Nick Hamilton',
            role: 'Real Estate Salesperson',
            bio: "",
            twitterUrl: 'https://twitter.com',
            facebookUrl: 'https://facebook.com',
            linkedinUrl: 'https://linkedin.com',
            ndlicense: '9631',
          },
        ]}
      />
{/*      <ClientsSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Some companies we've worked with"
        subtitle=""
        clients={props.data.prismic.clients.edges}
      />*/}
    </Layout>
  );
}

export default AboutPage;
