import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash.get';

import Section from './Section';
import SectionHeader from './SectionHeader';
import Clients from './Clients';

function ClientsSection(props) {
  const items = props.clients.map(client => ({
    name: client.node.name,
    image: (
      <Img
        fixed={client.node.logoSharp.childImageSharp.fixed}
        alt={
          client.node.logo.alt
            ? client.node.logo.alt
            : get(client.node, 'name.0.text', '')
        }
      />
    ),
    width: client.node.logoSharp.childImageSharp.fixed.width,
  }));

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
          data-aos="fade"
        />
        <Clients items={items} />
      </div>
    </Section>
  );
}

export default ClientsSection;
