import React from 'react';

function Avatar(props) {
  const { image, size, alt, ...otherProps } = props;

  return (
    <figure className={'image' + (size ? ` is-${size}x${size}` : '')}>
      {typeof image === 'string' ? (
        <img className="is-rounded" src={image} alt={alt} {...otherProps} />
      ) : (
        image
      )}
    </figure>
  );
}

export default Avatar;
